<template>
    <r-e-dialog title="编辑租金方式" :visible="dialogVisible" show-footer @click-submit="handleSubmitPublish"
                @click-cancel="handleCancel" @close="handleCancel" width="600px">
        <el-form ref="formPublish" label-width="110px" size="small" :model="formPublish" :rules="rules">
            <el-form-item label="小区" prop="name">
                <el-input disabled v-model="formPublish.name"/>
            </el-form-item>
<!--            <el-form-item label="单价" prop="monthPriceAmount">-->
<!--                <el-input v-model="formPublish.monthPriceAmount">-->
<!--                    <template slot="append">元</template>-->
<!--                </el-input>-->
<!--            </el-form-item>-->
          <el-form-item label="租金方式" prop="rentalAmountType">
            <el-select v-model="formPublish.rentalAmountType" placeholder="请选择租金方式" style="width: 440px">
              <el-option label="面积乘单价" value="1"></el-option>
              <el-option label="固定金额" value="0"></el-option>
            </el-select>
          </el-form-item>
<!--          <el-form-item label="是否重置金额" prop="isResetAmount">-->
<!--            <el-select v-model="formPublish.isResetAmount" placeholder="请选择计算方式" style="width: 440px">-->
<!--              <el-option label="所有房间" :value="true"></el-option>-->
<!--              <el-option label="不重置" :value="false"></el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--            <el-form-item label="折扣范围最小" prop="percentageMin" >-->
<!--                <el-input type="number" v-model="formPublish.percentageMin">-->
<!--                    <template slot="append">%</template>-->
<!--                </el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="折扣范围最大" prop="percentageMax">-->
<!--                <el-input type="number" v-model="formPublish.percentageMax">-->
<!--                    <template slot="append">%</template>-->
<!--                </el-input>-->
<!--            </el-form-item>-->

        </el-form>
    </r-e-dialog>
</template>

<script>
import {ParseFloatFormat, ParseIntFormat} from "@custom/index";
import {communityDefaultAmount} from "@/api/community-management";
import {MessageSuccess,MessageWarning} from "@custom/message";
import el from "element-ui/src/locale/lang/el";

export default {
    name: "layer-add-edit-unit-price",
    data() {
        return {
            dialogVisible: false,
            formPublish: {
                uuid: null,
                monthPriceAmount: null,
                rentalAmountType:'1',
                isResetAmount:null,
                percentageMax:null,
                percentageMin:null,
            },
            rules: {
                monthPriceAmount: [{required: true, message: "请输入单价", trigger: "blur"},],
                // sixMonthDiscount: [{required: true, message: "请输入半年折扣", trigger: "blur"},],
                yearDiscount: [{required: true, message: "请输入年折扣", trigger: "blur"},],
                rentalAmountType: [{required: true, message: "请选择租金方式", trigger: "blur"},],
                isResetAmount: [{required: true, message: "请选择是否重置", trigger: "blur"},],
                percentageMax:[{required: true, message: "请输入最大折扣", trigger: "blur"},],
                percentageMin:[{required: true, message: "请输入最小折扣", trigger: "blur"},]
            },
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
        };
    },
    methods: {
        //打开编辑单价弹窗方法
        openDialog(data) {
            //拆分传递过来的数据
            let {name, uuid, yearDiscount, monthPriceAmount,rentalAmountTypeCode} = data;
            //金额转换
            monthPriceAmount = ParseFloatFormat(monthPriceAmount);
            //赋值展示
            this.formPublish = {name, uuid, yearDiscount, monthPriceAmount,rentalAmountType:String(rentalAmountTypeCode)}
            //打开弹窗
            this.dialogVisible = true;
        },
        // 弹框确认方法
        handleSubmitPublish() {
            let that = this;
            //校验表单
            this.$refs["formPublish"].validate((valid) => {
                if (valid) {
                    //拆分数据
                    let {uuid, monthPriceAmount,rentalAmountType} = that.formPublish;
                    // const isValid = this.validateNumbers(percentageMin,percentageMax)
                    // if(!isValid){
                    //   MessageWarning("租金范围错误");
                    //   return
                    // }
                    //金额转换
                    // monthPriceAmount = ParseIntFormat(monthPriceAmount);
                    //组装数据
                    let params = {uuid,rentalAmountType:Number(rentalAmountType),}
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});
                    //调用接口，编辑单价
                    communityDefaultAmount(params).then(res => {
                        MessageSuccess("编辑成功");
                        that.handleCancel();
                    }).finally(() => loading.close());
                } else {
                    return false;
                }
            });
        },
      validateNumbers(num1, num2){
        num1 = Number(num1)
        num2 = Number(num2)
        // 检查两个数字是否都在0到100之间
        if (num1 < 0 || num1 > 100 || num2 < 0 || num2 > 100) {
          return false;
        }
        // 检查第一个数字是否小于第二个数字
        if (num1 >= num2) {
          return false;
        }
        return true;
      },
        // 弹框关闭方法
        handleCancel() {
            //重置表单
            this.$refs["formPublish"].resetFields();
            // 取消、关闭弹窗
            this.dialogVisible = false;
            //调用上层方法查询
            this.$emit("handleSearch");
        },

    }
}
</script>

<style scoped>

</style>
